import React from "react";
import About from "../about";
import Experience from "../exprience";
import Project from "../project";
import Contact from "../contact"
import "./style.css";


function Home() {
  return (
    <div class="home-bg">
      <About/>
      {/* <Experience type={true}/> */}
      {/* <Project type={true}/> 
      <Contact/>      */}
    </div>
  );
}

export default Home;
